<template>
  <div id="user-view">
    <v-row v-if="author && author.id">
      <v-col
        cols="12"
        md="4"
      >
        <v-card

          style="height: 100%"
          class=""
        >
          <v-card-title>
            {{ $t("Auteur") }}
          </v-card-title>
          <v-card-text class="text--primary">
            <v-row class="align-center">
              <v-col
                class="text-center shrink"
                order="1"
                order-md="2"
              >
                <v-card
                  flat
                  style="height: 100%"
                >
                  <v-avatar
                    :color="author.avatar ? '' : 'primary'"
                    :class="author.avatar ? '' : 'v-avatar-light-bg primary--text'"
                    width="70"
                    height="70"
                    rounded
                  >
                    <v-img

                      :lazy-src="require(`@/assets/images/avatars/1.png`)"
                      :src="author.avatar || require(`@/assets/images/avatars/1.png`)"
                      height="70"
                      contain
                    ></v-img>
                  </v-avatar>

                  <!--        <div class="font-weight-bold text-xl">
                           {{ author.full_name }}
                 &lt;!&ndash;          #{{ author.reference || author.id }}&ndash;&gt;
                         </div>-->
                </v-card>
              </v-col>
              <v-col
                class="grow"
                order="2"
                order-md="1"
              >
                <v-row no-gutters>
                  <v-col
                    cols="12"
                    class="d-flex justify-start"
                  >
                    <span class="shrink">{{ $t("Nom") }} :</span>
                    <div class="text--primary font-weight-semibold ml-2">
                      {{ author.full_name }}
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-start"
                  >
                    <span class="shrink text-no-wrap">{{ $t("Adresse") }} :</span>
                    <div class="text--primary font-weight-semibold ml-2">
                      {{ author.addresses.map((e) => e.address ).join(";") }}
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex justify-start"
                  >
                    <span class="shrink text-no-wrap">{{ $t("Adresses emails") }} :</span>
                    <div class="text--primary font-weight-semibold ml-2">
                      {{ author.email?author.email.join('/ '):'' }}
                    </div>
                  </v-col>
                  <v-col
                    v-if="formatPhoneNumber(author).length > 3"
                    cols="12"
                    class="d-flex justify-start"
                  >
                    <span class="shrink text-no-wrap">{{ $t("Téléphones") }} :</span>
                    <div class="text--primary font-weight-semibold ml-2">
                      {{ formatPhoneNumber(author) }}
                    </div>
                  </v-col>
                  <v-col
                    v-if="author.source"
                    cols="12"
                    class="d-flex justify-start"
                  >
                    <span class="shrink text-no-wrap">{{ $t("Source") }} :</span>
                    <div class="text--primary font-weight-semibold ml-2">
                      {{ author.source ? author.source.source_name : '-' }}
                    </div>
                  </v-col>
                  <v-col
                    v-if="author.status"
                    cols="12"
                    class="d-flex justify-start"
                  >
                    <span class="shrink text-no-wrap">{{ $t("Statut") }} :</span>
                    <div class="text--primary font-weight-semibold ml-2">
                      <v-chip
                        small
                        :color="author.status? author.status.color: ''"
                      >
                        {{ author.status ? author.status.name : '-' }}
                      </v-chip>
                    </div>
                  </v-col>
                  <v-col
                    v-if="author.author_ref"
                    cols="12"
                    class="d-flex justify-start"
                  >
                    <span class="shrink text-no-wrap">{{ $t("Référence") }} :</span>
                    <div class="text--primary font-weight-semibold ml-2">
                      {{ author.author_ref }}
                    </div>
                  </v-col>
                  <v-col
                    v-if="author.compte_auteur"
                    cols="12"
                    class="d-flex justify-start"
                  >
                    <span class="shrink text-no-wrap">{{ $t("Compte auteur") }} :</span>
                    <div class="text--primary font-weight-semibold ml-2">
                      {{ author.compte_auteur }}
                    </div>
                  </v-col>
                  <v-col
                    v-if="author.profession"
                    cols="12"
                    class="d-flex "
                  >
                    <span class="shrink text-no-wrap">{{ $t("Profession") }} :</span>
                    <div class="text--primary font-weight-semibold ml-2">
                      {{ author.profession ? author.profession.label:'' }}
                    </div>
                  </v-col>
                  <v-col
                    cols="12"
                    class="d-flex "
                  >
                    <span class="shrink text-no-wrap">{{ $t("Complétion du profil") }} :</span>
                    <div class="text--primary font-weight-semibold ml-2 d-flex align-center" style="width: 200px">
                      <v-btn
                        v-if="$can('edit','Author')"
                        icon x-small  :to="{name:'author-info',params:{id:author.id}}" :color="getAuthorColor()">
                        <v-icon
                          size="16"
                          class="me-2"
                        >
                          {{ mdiFileDocumentEditOutline }}
                        </v-icon>
                      </v-btn>
                      <v-progress-linear
                        :value="author.progress || 0"
                        :color="getAuthorColor()"
                        height="15"

                        rounded
                      >
                        <span class="bg-white white rounded px-1 py-0 body-2" >{{ author.progress || 0 }}%</span>
                      </v-progress-linear>
                    </div>

                  </v-col>
                </v-row>
              </v-col>

            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          style="height: 100%"
          class=""
        >
          <v-card-title>
            {{ $t("Détails") }}
          </v-card-title>
          <v-card-text class="text--primary">
            <v-row no-gutters>
              <v-col
                cols="12"
                class="d-flex justify-start"
              >
                <span>{{ $t("Age") }} :</span>
                <div class="text--primary font-weight-semibold ml-2">
                  {{ author.age || '-' }} ans
                </div>
              </v-col>
              <v-col
                cols="12"
                class="d-flex"
              >
                <span>{{ $t("Type d’auteur") }} :</span>
                <div class="text--primary font-weight-semibold ml-2">
                  {{ author.author_type ? author.author_type.label:'' }}
                </div>
              </v-col>
              <v-col
                cols="12"
                class="d-flex justify-start"
              >
                <span>{{ $t("Informations particulières") }} :</span>
                <div class="text--primary font-weight-semibold ml-2">
                  {{ author.author_state ? author.author_state.label:'' }} <span v-if="author.reason_for_anonymity">({{ author.reason_for_anonymity ? author.reason_for_anonymity : '-' }})</span>
                </div>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col
        cols="12"
        md="4"
      >
        <v-card
          style="height: 100%"
          class="border-b-lg justify-space-between fill-height"

        >
          <v-card-text class="text--primary ">
            <v-row class=" justify-center align-center align-content-center ">
              <v-col class="shrink">
                <v-avatar
                  size="40"
                  :class="` rounded ${getColor()} `"
                  tile
                  rounded
                >
                  <v-icon
                    color="white"
                    size="30"
                  >
                    {{ mdiCalendarOutline }}
                  </v-icon>
                </v-avatar>
              </v-col>
              <v-col class="flex-grow-1 grow text-left text-h5 font-weight-black bold" >
                {{ formatDate(author.last_contacted_date) }}
              </v-col>
              <v-col
                cols="12"
                class="py-0 font-weight-black "
              >
                {{ $t("Date de dernière discussion") }}
              </v-col>
              <v-col
                cols="12"
                class="py-0 font-weight-light bold " :class="`text-${getColor()} text--${getColor()}`"
              >
                {{ author.last_contacted_ago }}
              </v-col>


            </v-row>
          </v-card-text>

          <v-card-actions>
            <v-btn
              outlined
              :color="getColor()"
              :loading="loadingDate"
              small
              @click="loadDate"
            >
              {{ $t("Actualiser") }}
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
<!--      <v-col cols="12">
        <h3 class="text-h6">
          {{ $t("Données liées à l'auteur") }}
        </h3>
      </v-col>-->

<!--      <v-col cols="12">
        <h3 class="text-h6">
          {{ $t("Expérience dans le domaine éditorial") }}
        </h3>
      </v-col>-->
      <v-col cols="12" md="3">
        <v-card style="height: 100%">
          <v-card-title class="align-start">
            <span>{{ $t("Evénements réseaux sociaux")}}</span>
          </v-card-title>

          <v-card-text style="max-height: 450px" class="overflow-y-auto">
            <p v-if="!(author.author_events || []).length">{{ $t("Aucun evénements renseigné") }}</p>
            <v-timeline
              dense
              class="timeline-custom-dense timeline-custom-dots"
            >
              <v-timeline-item
                v-for="(ev,index) in author.author_events"
                :key="ev.id"
                small
                :color="`${ev.network?ev.network.color:(index%2?'primary':'info')}`"
              >
                <div class="d-flex justify-space-between align-center flex-wrap">
                  <h4 class="font-weight-semibold me-1">
                    {{ ev.label }}
                  </h4>
                </div>
                <div>
                  <small v-if="ev.eventtype" class="text-no-wrap font-weight-medium">{{ ev.eventtype.label }}</small>
                </div>

                <small class="text-no-wrap font-weight-medium">{{ formatDate(ev.date) }}</small>
                <p class="my-1 d-flex align-center " v-if="ev.network">
                  <v-avatar
                    rounded
                    size="30"
                    color="#5e566917"
                    class="me-1"
                  >
                    <v-img
                      contain
                      :src="ev.network.logo"
                      height="20"
                    ></v-img>
                  </v-avatar> {{ ev.network.label }}
                </p>
                <v-btn outlined x-small :color="`${index%2?'primary':'info'}`" :href="ev.location" target="_blank" class="mt-2" v-if="ev.location">
                  <v-icon
                    class="me-1"
                  >
                    {{ mdiLink }}
                  </v-icon>
                  <span class="">
                    {{ $t("Voir") }}
                  </span>
                </v-btn>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card style="height: 100%">
          <v-card-title class="align-start">
            <span>{{ $t("Participation à des salons") }}</span>
          </v-card-title>

          <v-card-text style="max-height: 450px" class="overflow-y-auto">
            <p v-if="!(author.author_salons || []).length">{{ $t("Aucun salons renseigné") }}</p>
            <v-timeline
              dense
              class="timeline-custom-dense timeline-custom-dots"
            >
              <v-timeline-item
                v-for="(ev,index) in author.author_salons"
                :key="ev.id"
                small
                :color="`${index%2?'primary':'info'}`"
              >
                <div class="d-flex justify-space-between align-center flex-wrap">
                  <h4 class="font-weight-semibold me-1">
                    {{ ev.label }}
                  </h4>
                </div>
                <small class="text-no-wrap font-weight-medium">{{ formatDate(ev.date) }}</small>
                <p class="my-1">
                  {{ ev.location }}
                </p>
<!--                <v-btn outlined x-small color="info" :href="ev.location" target="_blank" class="mt-2" v-if="ev.location">
                  <v-icon
                    class="me-1"
                  >
                    {{ mdiLink }}
                  </v-icon>
                  <span class="">
                    {{ $t("Voir") }}
                  </span>
                </v-btn>-->
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card style="height: 100%">
          <v-card-title class="align-start">
            <span>{{ $t("Séances de dédicaces") }}</span>
          </v-card-title>

          <v-card-text style="max-height: 450px" class="overflow-y-auto">
            <p v-if="!(author.author_sessions || []).length">{{ $t("Aucun séances de dédicaces renseigné") }}</p>
            <v-timeline
              dense
              class="timeline-custom-dense timeline-custom-dots"
            >
              <v-timeline-item
                v-for="(ev,index) in author.author_sessions"
                :key="ev.id"
                small
                :color="`${index%2?'primary':'info'}`"
              >
                <div class="d-flex justify-space-between align-center flex-wrap">
                  <h4 class="font-weight-semibold me-1">
                    {{ ev.label }}
                  </h4>
                </div>
                <small class="text-no-wrap font-weight-medium">{{ formatDate(ev.date) }}</small>
                <p class="my-1">
                  {{ ev.location }}
                </p>
<!--                <v-btn outlined x-small color="info" :href="ev.location" target="_blank" class="mt-2" v-if="ev.location">
                  <v-icon
                    class="me-1"
                  >
                    {{ mdiLink }}
                  </v-icon>
                  <span class="">
                    {{ $t("Voir") }}
                  </span>
                </v-btn>-->
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card style="height: 100%">
          <v-card-title class="align-start">
            <span>{{ $t("Publication dans les médias") }}</span>
          </v-card-title>

          <v-card-text style="max-height: 450px" class="overflow-y-auto">
            <p v-if="!(author.author_media_publications || []).length">{{ $t("Aucun publication dans les médias renseigné") }}</p>
            <v-timeline
              dense
              class="timeline-custom-dense timeline-custom-dots"
            >
              <v-timeline-item
                v-for="(ev,index) in author.author_media_publications"
                :key="ev.id"
                small
                :color="`${index%2?'primary':'info'}`"
              >
                <div class="d-flex justify-space-between align-center flex-wrap">
                  <h4 class="font-weight-semibold me-1">
                    {{ ev.media_type?ev.media_type.label:'' }}
                  </h4>
                </div>
                <h5 v-if="ev.media" class="font-weight-semibold me-1">
                  {{ ev.media }}
                </h5>
                <small class="text-no-wrap font-weight-medium">{{ formatDate(ev.date) }}</small>
                <p class="mb-0">
                  {{ ev.location }}
                </p>
                <v-btn outlined x-small :color="`${index%2?'primary':'info'}`" :href="ev.link" target="_blank" class="mt-2" v-if="ev.link">
                  <v-icon
                    class="me-1"
                  >
                    {{ mdiLink }}
                  </v-icon>
                  <span class="">
                    {{ $t("Voir") }}
                  </span>
                </v-btn>
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>

<!--      <v-col cols="12">
        <h3 class="text-h6">
          {{ $t("Données liées aux ouvrages de l'auteur") }}
        </h3>
        <h3 class="text-h6">
          <span>{{author.publications_count || 0 }}</span>  {{ $t("ouvrages publiés") }}
        </h3>



      </v-col>-->

      <v-col cols="12" md="3" xl="3">
        <v-card style="height: 100%">
          <v-card-title class="align-start">
            <span>{{ $t("Réseaux sociaux") }}</span>
          </v-card-title>

          <v-card-text style="max-height: 250px" class="overflow-y-auto">
            <p v-if="!(author.author_networks || []).length">{{ $t("Aucun réseaux sociaux renseigné") }}</p>
            <div
              v-for="(network,index) in author.author_networks"
              :key="network.id"
              :class="`d-flex align-center ${index >0 ? 'mt-2':''}`"
            >
              <v-avatar
                rounded
                size="38"
                color="#5e566917"
                class="me-4"
              >
                <v-img
                  contain
                  :src="network.network.logo"
                  height="20"
                ></v-img>
              </v-avatar>

              <div class="d-flex align-center flex-grow-1 flex-wrap">
                <div>
                  <h4 class="font-weight-bold">
                    {{ network.network.label }}
                  </h4>
                  <h4 class="font-weight-medium">
                    {{ network.followers }} {{ $t("followers")}}
                  </h4>
                  <span class="text-xs text-no-wrap">{{ network.status ?$t('Communauté active'):$t('Communauté inactive') }}</span>
                </div>

                <v-spacer></v-spacer>

                <div class="ms-1" style="width: 100px; max-width: 100px">
                  <small class="text--primary font-weight-medium mb-1">
                    {{ network.followers }} followers
                  </small>
                  <v-progress-linear
                    :value="getProgressValue(network.followers)"
                    :color="network.network.color"
                    striped
                    rounded
                  ></v-progress-linear>
                </div>
              </div>
            </div>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="3">
        <v-card style="height: 100%">
          <v-card-title class="align-start">
            <span>{{ $t("Prix littéraires") }}</span>
          </v-card-title>

          <v-card-text style="max-height: 450px" class="overflow-y-auto">
            <p v-if="!(author.awards || []).length">{{ $t("Aucun Prix littéraires renseigné") }}</p>
            <v-timeline
              dense
              class="timeline-custom-dense timeline-custom-dots"
            >
              <v-timeline-item
                v-for="(ev,index) in author.awards"
                :key="ev.id"
                small
                :color="`${index%2?'primary':'info'}`"
              >
                <div class="d-flex justify-space-between align-center flex-wrap">
                  <h4 class="font-weight-semibold me-1">
                    {{ ev.name }} {{ ev.category_label?`(${ev.category_label})`:'' }}
                  </h4>
                </div>
                <small class="text-no-wrap font-weight-medium">{{ ev.year }}</small>
                <p class="my-1">
                  {{ ev.category }}
                </p>
                <p class="my-1" v-if="ev.genre">
                  Genre : {{ ev.genre?ev.genre.label:'' }}
                </p>
                <p class="my-1" v-if="ev.publication">
                  Dossier : {{ ev.publication }} - {{ ev.publication?ev.publication.title:'' }}
                </p>
                <!--                <v-btn outlined x-small color="info" :href="ev.location" target="_blank" class="mt-2" v-if="ev.location">
                                  <v-icon
                                    class="me-1"
                                  >
                                    {{ mdiLink }}
                                  </v-icon>
                                  <span class="">
                                    {{ $t("Voir") }}
                                  </span>
                                </v-btn>-->
              </v-timeline-item>
            </v-timeline>
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="12" md="6" xl="6">
        <v-card>

          <v-data-table
            class="fixed-table"
            :headers="tableColumnHeaders"
            :items="author.payments || []"
            :options.sync="options"
            :page.sync="page"
            :server-items-length="(author.payments || []).length"
            :loading="loading"
            :loading-text="$t('Chargement des éléments')"
            :no-data-text="$t('Pas de données')"
            :no-results-text="$t('Aucun résultat')"
            :mobile-breakpoint="250"
            hide-default-footer
            :show-select="false"
            dense fixed-header :height="400"
            @page-count="pageCount = $event"
          >
            <template #item="options">
              <Row :options="options" :show-select="false"
                   :config="{ url: '/publications/action/', author: 'author', manuscript: 'manuscript', resource: 'Paiment' }"
              >

              </Row>
            </template>
            <!-- status -->
            <!--                <template #[`item.signature_date`]="{item}">
                              <div>{{ formatDate(item.signature_date) }}</div>
                            </template>
                            <template #[`item.llb_website`]="{item}">
                              <div>{{ formatDate(item.llb_website) }}</div>
                            </template>
                            <template #[`item.author_payment`]="{item}">
                              <div>{{ formatDate(item.author_payment) }}</div>
                            </template>-->
          </v-data-table>
        </v-card>
      </v-col>
      <v-col cols="12" md="12">
        <v-row>
          <v-col
            v-for="(pub, index) in author.publication_fulls"
            cols="12"
            md="3"
          >
            <v-card

              style="height: 100%"
              class=""
            >
              <v-card-text class="text--primary">
                <v-row>
                  <v-col
                    class="grow"
                    order="2"
                    order-md="1"
                  >
                    <v-row no-gutters>
                      <v-col
                        cols="12"
                      >
                        <h4 class="text-h6">{{ pub.title }}</h4>
                      </v-col>
                      <v-col
                        cols="12"
                        class="d-flex justify-start"
                      >
                        <span class="shrink">{{ $t("ISBN") }} :</span>
                        <div class="text--primary font-weight-semibold ml-2">
                          {{ pub.isbn }}
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        class="d-flex justify-start"
                      >
                        <span class="shrink text-no-wrap">{{ $t("Manuscrit reçu le") }} :</span>
                        <div class="text--primary font-weight-semibold ml-2">
                          {{ pub.manuscript_full?formatDate(pub.manuscript_full.receipt_date):'' }}
                        </div>
                      </v-col>
                      <v-col
                        cols="12"
                        class="d-flex justify-start"
                      >
                        <span class="shrink text-no-wrap">{{ $t("Ouvrage publié le") }} :</span>
                        <div class="text--primary font-weight-semibold ml-2">
                          {{ formatDate(pub.publishing_bod) }}
                        </div>
                      </v-col>

                    </v-row>
                  </v-col>

                </v-row>
              </v-card-text>
            </v-card>
          </v-col>
        </v-row>
      </v-col>



    </v-row>
    <v-row v-else>
      <v-col
        cols="12"
        sm="9"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col
        cols="12"
        sm="3"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
      </v-col>
      <v-col
        cols="12"
        sm="6"
      >
        <v-skeleton-loader
          class="mx-auto"
          max-width="100%"
          type="card"
        ></v-skeleton-loader>
      </v-col>
    </v-row>
    <v-dialog
      v-model="isFormActive"
      touchless
      :right="!$vuetify.rtl"
      :width="$vuetify.breakpoint.smAndUp ? 800 : '100%'"
    >
      <author-form
        v-model="author"
        :is-form-active="isFormActive"
        :with-addresses="true"
        @refresh-data="fetchItem"
      >
        <template v-slot:form-title>
          <span class="font-weight-semibold text-base text--primary">{{
            author.id ? $t('Modifier') : $t('Ajouter un auteur')
          }}</span>
          <v-spacer />
          <v-spacer></v-spacer>
          <v-btn
            icon
            small
            @click="isFormActive = !isFormActive"
          >
            <v-icon size="22">
              {{ mdiClose }}
            </v-icon>
          </v-btn>
        </template>
        <template #form-cancel>
          <v-btn
            :loading="loading"
            color="secondary"
            outlined
            type="reset"
            @click="isFormActive = !isFormActive"
          >
            {{ $t('Annuler') }}
          </v-btn>
        </template>
      </author-form>
    </v-dialog>
  </div>
</template>

<script>
import {computed, onUnmounted, ref} from '@vue/composition-api'
import {
  mdiAccountOutline,
  mdiLockOutline,
  mdiBookmarkOutline,
  mdiBellOutline,
  mdiLinkVariant,
  mdiAccountCogOutline,
  mdiBookAccountOutline,
  mdiBookMultipleOutline,
  mdiCity,
  mdiDelete,
  mdiPencil,
  mdiClose,
  mdiCalendarOutline,
  mdiLink,
  mdiFileDocumentEditOutline,
} from '@mdi/js'
import axios from '@axios'
import router from '@/router'
import store from '@/store'

// eslint-disable-next-line object-curly-newline
import AuthorBioPanel from './author-bio-panel/AuthorBioPanel.vue'
import authorStoreModule from '../authorStoreModule'
import AuthorTabOverview from './author-tab-overview/AuthorTabOverview.vue'
import AuthorTabSecurity from './author-tab-security/AuthorTabSecurity.vue'

import AccountSettingsAccount from '@/views/pages/account-settings/AccountSettingsAccount.vue'
import Activities from '@/views/apps/author/author-view/author-tab-overview/Activities'
import KnowledgeBase from '@/views/pages/knowledge-base/KnowledgeBase'
import Addresses from '@/views/apps/author/author-list/Addresses'
import Manuscripts from '@/views/apps/manuscript/Manuscripts'
import Publications from '@/views/apps/publications/Publications'
import AuthorForm from '@/views/apps/author/author-list/AuthorForm.vue'

import { avatarText, formatDate } from '@core/utils/filter'
import AuthorNetworks from '@/views/apps/author/author-view/components/AuthorNetworks.vue'
import controller from '../author-list/useAuthorsList'
import AuthorEvents from '@/views/apps/author/author-view/components/AuthorEvents.vue'
import AuthorSalons from '@/views/apps/author/author-view/components/AuthorSalons.vue'
import AuthorSessions from '@/views/apps/author/author-view/components/AuthorSessions.vue'
import AuthorPublications from '@/views/apps/author/author-view/components/AuthorPublications.vue'
import AuthorState from '@/views/apps/author/author-view/components/AuthorState.vue'
import Row from "@/components/Row.vue";

export default {
  components: {
    Row,
    AuthorState,
    AuthorPublications,
    AuthorSessions,
    AuthorSalons,
    AuthorEvents,
    AuthorNetworks,
    AuthorForm,
  },
  methods: { formatDate },
  setup() {
    const {
      downloadObject,
      tableColumns,
      filters,
      searchQuery,
      payFilter,
      sourceFilter,
      statusFilter,
      totalItems,
      loading,
      options,
      authorTotalLocal,
      selectedRows,
      snackBarMessage,
      isSnackbarBottomVisible,

      deleteItem,
      confirmDelete,
      deleteDialog,
      fetchAuthors,
      resolveAuthorRoleIcon,
      resolveAuthorTotalIcon,
      resolveAuthorStatusVariant,
      resolveAuthorStatusName,
      resolveAuthorRoleVariant,

    } = controller()

    const USER_APP_STORE_MODULE_NAME = 'app-author'

    // Register module
    if (!store.hasModule(USER_APP_STORE_MODULE_NAME)) store.registerModule(USER_APP_STORE_MODULE_NAME, authorStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(USER_APP_STORE_MODULE_NAME)) store.unregisterModule(USER_APP_STORE_MODULE_NAME)
    })

    const author = ref({})
    const isFormActive = ref(false)
    const loadingDate = ref(false)
    const authorTab = ref(null)
    /*const options = ref({
      rowsPerPage: 50,
      itemsPerPage: 50,
      page: 1,
      sortDesc: [true],
    })*/
    const selectedPlan = ref('')
    const isPlanUpgradeDialogVisible = ref(false)

    const tableColumnHeaders = ref([
      {
        align: 'left',
        text: 'Dossier',
        value: 'id',
        fixed: true,
        class: ' 50px ',
        cellClass: 'py-1',
        sortable: false,
        editable: false,
        /*width: '100px',*/
        info: 'Ajouter un commentaire sur le dossier ?',
        comment_reference: 'type',
        comments: 'publication_records',
        fields: [
          { type: 'textarea', label: 'Commentaire', value: 'comment' },
        ],
        filter: { place_holder: 'Numero...', type: 'text' },
        filters: [
          {
            key: 'id',
            text: 'Dossier',
            place_holder: 'Dossier',
            type: 'range',
            min: 0,
            max: 10000,
            step: 1,
            column: 'publications.id',
            noLimit: false,
            value: [],
          },

          /*  {
              text: 'Condition',
              place_holder: 'Condition',
              type: 'select',
              is_condition: true,
              values: [
                { id: 0, label: 'Est vide' },
                { id: 1, label: 'N\'est pas vide' },
              ],
              label: 'label',
              id: 'id',
              multiple: false,
              key: 'publication_errors_before_correction',
              column: 'id',
            }, */
        ],
      },
      {
        text: 'Titre',
        value: 'title',
        align: 'left',
        width: '200px',
        sortable: false,
        filter: {
          place_holder: '',
          type: 'text',

          /*  columns: ['manuscripts.title'],
            join_table: 'manuscripts',
            join_column: 'manuscripts.id',
            local_column: 'publications.manuscript_id', */
        },
      },
      {
        text: 'Date signature',
        value: 'signature_date',
        align: 'left',
        type: 'date',
        date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
        colors: 'publication_colors',
        comments: 'publication_records',
        color_reference: 'colomn_name',
        comment_reference: 'type',
        editable: false,
        sortable: false,
        hideEdit: true,
        info: 'Confirmer le traitement de "Date signature" ?',
        fields: [
          {
            type: 'color',
            label: 'Couleur de fond',
            required: true,
            value: 'color_id',
            item_value: 'id',
            item_text: 'color_name',
            items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          },
          { type: 'textarea', label: 'Commentaire', value: 'comment' },
        ],
        filters: [
          {
            key: 'signature_date',
            text: 'Date signature',
            place_holder: 'Date1 - Date2',
            type: 'date',
            month: true,
            menu: false,
            range: true,
            column: 'signature_date',
            noLimit: true,
          },
          {
            text: 'Couleur de fond',
            place_holder: 'Couleur de fond',
            type: 'select',
            values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)).filter(ele => ele.type !== 'visual_idea'),
            label: 'color_name',
            id: 'id',
            multiple: false,
            key: 'publication_colors.colomn_name',

            /* columns: 'publication_colors.color_id', */
            join_table: 'publication_colors',
            join_column: 'publication_colors.publication_id',
            local_column: 'publications.id',
            conditions: [
              {
                name: 'publication_colors.colomn_name',
                value: 'signature_date',
                single: true,
              },
              {
                name: 'publication_colors.color_id',
                value: null,
                single: false,
              },
            ],
          },
          {
            text: 'Condition',
            place_holder: 'Condition',
            type: 'select',
            is_condition: true,
            values: [
              { id: 0, label: 'Est vide' },
              { id: 1, label: 'N\'est pas vide' },
            ],
            label: 'label',
            id: 'id',
            multiple: false,
            key: 'publication_signature_date',
            column: 'signature_date',
          },
        ],
      },
      {
        text: 'Site LLB',
        value: 'llb_website',
        align: 'left',
        url: 'publication_llb_website',
        comment_column: 'publication_llb_website',
        blockBg: 'bg-grey',
        type: 'date',
        date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
        colors: 'publication_colors',
        comments: 'publication_records',
        color_reference: 'colomn_name',
        comment_reference: 'type',
        editable: false,
        sortable: false,
        hideValue: false,
        info: 'Confirmez-vous que l\'information a été publiée sur le site Web ?',
        fields: [
          {
            type: 'date', label: 'Site LLB', value: 'llb_website', required: false,
          },
          {
            type: 'color',
            label: 'Couleur de fond',
            value: 'color_id',
            item_value: 'id',
            item_text: 'color_name',
            items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          },
          { type: 'textarea', label: 'Commentaire', value: 'comment' },
        ],
        functions: [
          (th, td) => {

          },
        ],
        filter: {
          place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
        },
        filters: [
          {
            key: 'llb_website',
            text: 'Site LLB',
            place_holder: 'Date1 - Date2',
            type: 'date',
            menu: false,
            range: true,
          },
          {
            text: 'Couleur de fond',
            place_holder: 'Couleur de fond',
            type: 'select',
            values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
            label: 'color_name',
            id: 'id',
            multiple: true,
            key: 'publication_colors.colomn_name',

            join_table: 'publication_colors',
            join_column: 'publication_colors.publication_id',
            local_column: 'publications.id',
            conditions: [
              {
                name: 'publication_colors.colomn_name',
                value: 'llb_website',
                single: true,
              },
              {
                name: 'publication_colors.color_id',
                value: null,
                single: false,
              },
            ],
          },
          {
            text: 'Condition',
            place_holder: 'Condition',
            type: 'select',
            is_condition: true,
            values: [
              { id: 0, label: 'Est vide' },
              { id: 1, label: 'N\'est pas vide' },
            ],
            label: 'label',
            id: 'id',
            multiple: false,
            key: 'publication_llb_website',
            column: 'llb_website',
          },
        ],
      },
      {
        text: 'Paiement auteur',
        value: 'author_payment',
        align: 'left',
        url: 'paiement_author_payment',
        comment_column: 'paiement_author_payment',
        blockBg: 'bg-grey',
        type: 'date',
        date_format: { month: 'numeric', day: 'numeric', year: 'numeric' },
        colors: 'publication_colors',
        comments: 'publication_records',
        color_reference: 'colomn_name',
        comment_reference: 'type',
        sortable: false,
        editable: false,
        hideValue: false,
        info: 'Confirmez-vous que le paiement auteur a été reçu ?',

        /* fields: [
          {
            type: 'date', label: 'Paiement auteur', value: 'author_payment', required: false,
          },
          {
            type: 'color',
            label: 'Couleur de fond',
            value: 'color_id',
            item_value: 'id',
            item_text: 'color_name',
            items: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
          },
          { type: 'textarea', label: 'Commentaire', value: 'comment' },
        ], */
        /*  functions: [
          (th, td) => {

          },
        ], */
        cellClass: 'text-center',
        filter: {
          place_holder: 'Date1 - Date2', type: 'date', menu: false, range: true,
        },
        filters: [
          {
            key: 'author_payment',
            text: 'Paiement auteur',
            place_holder: 'Date1 - Date2',
            type: 'date',
            menu: false,
            range: true,
          },
          {
            text: 'Couleur de fond',
            place_holder: 'Couleur de fond',
            type: 'select',
            values: store.state.app.colors.filter(el => !store.state.app.settings.visual_idea_colors_ids.includes(el.id)),
            label: 'color_name',
            id: 'id',
            multiple: true,
            key: 'publication_colors.colomn_name',
            join_table: 'publication_colors',
            join_column: 'publication_colors.publication_id',
            local_column: 'publications.id',
            conditions: [
              {
                name: 'publication_colors.colomn_name',
                value: 'author_payment',
                single: true,
              },
              {
                name: 'publication_colors.color_id',
                value: null,
                single: false,
              },
            ],
          },
          {
            text: 'Condition',
            place_holder: 'Condition',
            type: 'select',
            is_condition: true,
            values: [
              { id: 0, label: 'Est vide' },
              { id: 1, label: 'N\'est pas vide' },
            ],
            label: 'label',
            id: 'id',
            multiple: false,
            key: 'publication_author_payment',
            column: 'author_payment',
          },
        ],
      },
    ])

    store.dispatch('app/fetchNetworks', { per_page: 500 })
    store.dispatch('app/fetchProfessions', { per_page: 500 })
    store.dispatch('app/fetchMediaTypes', { per_page: 500 })
    store.dispatch('app/fetchAuthorStates', { per_page: 500 })
    store.dispatch('app/fetchAuthorTypes', { per_page: 500 })

    const maxFollowers = computed(() => (author.value.author_networks?Math.max(...author.value.author_networks.map((net) => net.followers)):0))
    const fetchItem = () => {
      loading.value = true
      store
        .dispatch('app-author/fetchAuthor', { id: router.currentRoute.params.id })
        .then(response => {
          loading.value = false
          author.value = response.data
        })
        .catch(error => {
          loading.value = false
          if (error.response.status === 404) {
            author.value = {}
          }
        })
    }
    const loadDate = () => {
      loadingDate.value = true
      axios.get(`/author/${router.currentRoute.params.id}/check_last_contacted`).then(response => {
        loadingDate.value = false
        author.value = response.data
      })
        .catch(error => {
          loadingDate.value = false
        })
    }
    const getColor = () => {
      if (author.value.last_contacted_days <= 10) {
        return 'success'
      }
      if (author.value.last_contacted_days <= 20) {
        return 'warning'
      }

      return 'error'
    }
    const getAuthorColor = () => {
      if (!author.value.progress) return 'error'

      if (author.value.progress < 25) {
        return 'error'
      }
      if (author.value.progress <= 75) {
        return 'warning'
      }

      return 'success'
    }
    const getProgressValue = (followers) => {
      // Normalize the followers to a range of 0-100
      return (followers / maxFollowers.value) * 100
    }

    const plansList = [
      { text: 'Basic - $0/month', value: 'basic' },
      { text: 'Standard - $99/month', value: 'standard' },
      { text: 'Enterprise - $499/month', value: 'enterprise' },
      { text: 'Company - $999/month', value: 'company' },
    ]

    const tabs = [
      { icon: mdiAccountOutline, title: 'Historique des activités' },
      { icon: mdiAccountCogOutline, title: 'Paramètres' },
      { icon: mdiLockOutline, title: 'Sécurité' },

      /* { icon: mdiBookmarkOutline, title: 'Billing & Plan' },
      { icon: mdiBellOutline, title: 'Notifications' },
      { icon: mdiLinkVariant, title: 'Connections' }, */
    ]

    // ui
    const resolveCurrentPlanValue = plan => {
      if (plan === 'basic') return '0'
      if (plan === 'standard') return '99'
      if (plan === 'enterprise') return '499'
      if (plan === 'company') return '999'

      return '0'
    }

    const formatPhoneNumber = val => {
      if (val.country_id == (store.state.app.settings.default_country_id || 79)) {
        // let p = val.phone
        // var string = "02076861111"
        let string = val.phone
        if ((!string || !string.trim().length) && val.addresses) {
          return val.addresses.map(a => formatPhoneNumber(a)).join(', ')
        }
        if (string && string.length < 9) {
          string = `0${string}`
        }

        if (string) {
          if (string.length > 7 && string.length < 10) {
            const phone = string.replace(/(\d{2})(\d{2})(\d{2})(\d{2})/, '$1.$2.$3.$4')

            return phone
          }
          if (string.length > 9) {
            const phone = string.replace(/(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})/, '$1.$2.$3.$4.$5')

            return phone
          }

          const phone = string.replace(/(\d{2})(\d{2})(\d{7})/, '$1.$2.$3')

          return phone
        }

        return val.phone
      }

      return val.phone
    }

    const openDialog = () => {
      isFormActive.value = true
    }

    fetchItem()
    loadDate()

    return {
      getProgressValue,
      getAuthorColor,
      isFormActive,
      tabs,
      authorTab,
      plansList,
      selectedPlan,
      isPlanUpgradeDialogVisible,
      author,
      options,
      loading,
      loadingDate,
      loadDate,
      fetchAuthors,
      fetchItem,
      openDialog,
      avatarText,
      AuthorTabOverview,
      resolveCurrentPlanValue,
      getColor,
      mdiAccountOutline,
      mdiAccountCogOutline,
      mdiLockOutline,
      mdiBookAccountOutline,
      mdiBookMultipleOutline,
      mdiCity,
      mdiDelete,
      mdiPencil,
      mdiClose,
      mdiCalendarOutline,
      mdiLink,
      mdiFileDocumentEditOutline,
      formatPhoneNumber,
      tableColumnHeaders,
      page: 1,
      pageCount: 0,
      itemsPerPage: 10,
    }
  },
}
</script>

<style lang="scss">
@import '@core/preset/preset/apps/user.scss';
</style>
